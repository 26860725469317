<template>
  <div class="w-100">
    <h5 class="mb-0">{{ $t('trainingShow') }}</h5>
    <hr>
    <b-form @submit.prevent="save()" class="row align-items-center">
      <div class="col-4 col-md-3">
        <label class="mb-0" for="training">{{ $t('selectOption') }}</label>
      </div>
      <div class="col-4 col-md-3">
      <select name="training" id="training" class="form-control" v-model="team.training">
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
      </select>
      </div>
      <div class="col-4 col-md-3">
        <b-button type="submit" variant="primary" block>{{ $t('save') }}</b-button>
      </div>
    </b-form>
    <div class="w-100 text-center mt-3">
      <img v-if="team.training === '1'" src="../../../assets/images/formations/1.jpg" alt="Formación 1" class="img-thumbnail">
      <img v-if="team.training === '2'" src="../../../assets/images/formations/2.jpg" alt="Formación 2" class="img-thumbnail">
      <img v-if="team.training === '3'" src="../../../assets/images/formations/3.jpg" alt="Formación 3" class="img-thumbnail">
      <img v-if="team.training === '4'" src="../../../assets/images/formations/4.jpg" alt="Formación 4" class="img-thumbnail">
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      teamId: this.$route.params.teamId
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'console',
      'team',
      'user'
    ])
  },
  methods: {
    save () {
      const payload = {
        training: this.team.training
      }
      const path = `auth/${this.lang}/${this.console}/teams/${this.team.id}/update-training`
      this.$axios.post(path, payload).then(() => {
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
      })
    }
  }
}
</script>
